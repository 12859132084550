.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  text-shadow: 2px 2px #ffffff;
}

.Question{
  max-width: 90%;
  margin: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.VoteBtn{
  background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 32px 32px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Upheaval";
  text-shadow: 2px 2px #444;
  width: 90%;
  height: fit-content;
  box-shadow: inset 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0 rgba(0,0,0,0.5);
}

.VoteBtn:focus{
  transform:scale(0.98);
  box-shadow: 3px 2px rgba(0, 0, 0, 0.24);
  background-color: #049962; /* Green */
  color: #AAA;
  text-shadow: -2px -2px #444;
  
}

body{
  background: url("./assets/bg_Tile.png") center/20% repeat;
  -webkit-animation: scrolling 45s linear infinite;
  -moz-animation: scrolling 45s linear infinite;
  -o-animation: scrolling 45s linear infinite;
  animation: scrolling 45s linear infinite;
}

@keyframes scrolling{
  from{
    background-position: 0 0;
  }
  to{
    background-position: 100% 100%;
  }
}

.NameSub{
  font-size: calc(20px + 2vmin);

}

.LockedChoices{
  background-color: rgba(0,0,0,0.5);
  padding: 10%;
}